<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center border-bottom">
            <h4 class="mr-auto color-333">Login Logs</h4>
        </div>
        <el-row :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-bottom font-12">
            <el-col :span='12'>IP Address</el-col>
            <el-col class="text-right" :span='12'>Date time</el-col>
        </el-row>
        <el-row v-for="(item, index) in list.data" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-bottom font-14">
            <el-col :span='12'>{{ item.ip }}</el-col>
            <el-col :span='12' class="text-right">{{ item.create_time }}</el-col>
        </el-row>
        <div class="d-flex-center pt-20">
            <el-pagination
                :hide-on-single-page="true"
                background
                layout="prev, pager, next"
                :page-size="list.per_page"
                :current-page="list.current_page"
                :total="list.total"
                @current-change="onPage"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            query: { ...this.$route.query },
            list: {
                per_page: 1,
                last_page: 1,
                current_page: 1,
                total: 0,
                data: []
            }
        }
    },
    created: function() {
        this.fatchRecord()
    },
    methods: {
        fatchRecord: async function () {
            const { data } = await this.$request.post('user/login_log_pc', this.query)
            this.list = data
        },
        onSearch() {
            this.fatchRecord()
            this.$router.replace({
                query: this.query
            })
        },
        onPage(page) {
            this.query.page = page || 1
            this.onSearch()
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>